<template>
  <v-main>

    <v-container
        id="update-account-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-account-edit-outline"
          title="Edit user"
          class="px-5 py-3"
          ref="form"
      >

        <v-card-text>

          <v-card-text v-if="errors.length">
            <v-alert color="error"
                     dark
                     outlined
                     :key="error.id"
                     show variant="danger"
                     v-for="error in errors">
              {{ error }}
            </v-alert>
          </v-card-text>

          <v-form ref="updateAccountForm"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent="validate"
          >

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
                  class="font-weight-medium"
              >
                Account Number:
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                {{ user.accountNumber }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >

                <v-select
                    v-model="user.qualification"
                    label="Membership Qualification"
                    :items="qualifications"
                    item-text="description"
                    item-value="id"
                    return-object
                    :rules="rules.required"

                />

              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-textarea
                    v-model="user.recommenders"
                    label="Names of Recommenders (Comma-separated)"
                    rows="1"
                    :rules="rules.required"
                    v-if="computedShowRecommenders"
                ></v-textarea>
              </v-col>

            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.firstName"
                    label="First Name"
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.middleName"
                    label="Middle Name/Initial"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.lastName"
                    label="Last Name"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.address"
                    label="Address"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.addressLine2"
                    label="Address line 2 (optional)"
                    hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.city"
                    label="City"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    label="State"
                    v-model="computedState"
                    :items="statesList"
                    item-text="name"
                    item-value="code"
                    return-object
                    :rules="rules.required"

                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.postalCode"
                    label="Zip/Postal Code"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select

                    label="Country"
                    v-model="computedCountry"
                    :items="countriesList"
                    item-text="name"
                    item-value="code"
                    return-object
                    :rules="rules.required"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.phoneNumber"
                    label="Phone Number"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>

            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.idNumber"
                    label="ID Number"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    v-model="user.idType"
                    label="ID Type"
                    :items="idTypes"
                    item-text="name"
                    item-value="id"
                    return-object
                    :rules="rules.required"
                ></v-select>
              </v-col>

            </v-row>

            <v-row>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.idIssuedBy"
                    label="ID Issuing Country/State"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.idExpires"
                    label="ID Expiration Date"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.birthYear"
                    label="Year of Birth"
                    :rules="rules.numeric"

                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-radio-group
                    v-model="user.gender"
                    row
                    :rules="rules.required"
                >
                  <v-radio
                      v-for="(item, i) in genderOptions"
                      :key="i"
                      :label="item"
                      :value="item"
                  >
                  </v-radio>

                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-radio-group
                    v-model="user.isMarried"
                    row
                >
                  <template v-slot:label>
                    <div>Marital Status:</div>
                  </template>
                  <v-radio
                      label="Married"
                      :value="true">
                  </v-radio>
                  <v-radio
                      label="Not Married"
                      :value="false">
                  </v-radio>

                </v-radio-group>
              </v-col>
            </v-row>


            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >

                <v-select
                    v-model="user.qualification"
                    label="Membership Qualification"
                    :items="qualifications"
                    item-text="description"
                    item-value="id"
                    return-object
                    :rules="rules.required"
                />

              </v-col>
            </v-row>

            <v-row v-if="computedShowRecommenders">
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="recommenders[0].name"
                    v-bind:label="'Recommender 1 Name'"
                    :rules="rules.recommender"
                ></v-text-field>
                <v-text-field
                    v-model="recommenders[0].phoneNumber"
                    label="Recommender 1 Phone Number"
                    :rules="rules.numeric"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="recommenders[1].name"
                    label="Recommender 2 Name"
                    :rules="rules.recommender"
                ></v-text-field>
                <v-text-field
                    v-model="recommenders[1].phoneNumber"
                    label="Recommender 2 Phone Number"
                    :rules="rules.numeric"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="recommenders[2].name"
                    label="Recommender 3 Name"
                    :rules="rules.recommender"
                ></v-text-field>
                <v-text-field
                    v-model="recommenders[2].phoneNumber"
                    v-bind:label="'Recommender 3 Phone Number'"
                    :rules="rules.numeric"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <div class="my-2">
                  <v-btn
                      color="primary"
                      dark
                      type="submit"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-col>
            </v-row>

          </v-form>


        </v-card-text>

      </base-material-card>

    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import geoUtils from '@/utils/geography'
import validation from "@/utils/validation";
import {mapGetters} from "vuex";
import moment from "moment-timezone";

export default {
  name: 'CoreUpdateAccountView',

  created() {
    this.userId = this.getUserId

    if (this.isLoggedIn) {
      this.fetchUser()
    }

    this.statesList = geoUtils.fetchUsStates()
    this.countriesList = geoUtils.fetchCountries()
    this.fetchIdTypes()
    this.fetchQualifications()
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn',
      getUserId: 'getUserId'
    }),
    computedState: {
      get() {
        return geoUtils.findUsStateByCode(this.user.state)
      },
      set(value) {
        this.user.state = value.code
      }
    },
    computedCountry: {
      get() {
        return geoUtils.findCountryByCode(this.user.country)
      },
      set(value) {
        this.user.country = value.code
      }
    },
    computedShowRecommenders: function () {
      return (this.user.qualification !== null) && (this.user.qualification.id === 3)
    }
  },

  methods: {
    validate () {
      this.errors = []
      if(this.$refs.updateAccountForm.validate()) {
        this.updateUser()
      }
    },
    fetchIdTypes() {
      api
          .fetchIdTypes()
          .then(response => {
            this.idTypes = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchUser() {
      api
          .fetchUser(this.userId)
          .then(response => {
            this.user = response.data
            this.fetchRecommenders()
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    buildPayload() {
      let payload = {
        firstName: this.user.firstName,
        middleName: this.user.middleName,
        lastName: this.user.lastName,
        address: this.user.address,
        addressLine2: this.user.addressLine2,
        city: this.user.city,
        state: this.user.state,
        postalCode: this.user.postalCode,
        country: this.user.country,
        phoneNumber: this.user.phoneNumber,
        birthYear: this.user.birthYear,
        gender: this.user.gender,
        idNumber: this.user.idNumber,
        idType: this.user.idType.id,
        idExpires: this.user.idExpires,
        idIssuedBy: this.user.idIssuedBy,
        isSibling: this.user.isSibling,
        isMarried: this.user.isMarried,
        joined: moment(this.user.joined).format('YYYY-MM-DD'),
        roles: this.user.roles,
        status: this.user.status,
        qualificationId: this.user.qualification.id,
        comment: this.comment,
        recommenders: (this.user.qualification.id === 3) ? this.user.recommenders : ''
      }
      payload.idTypeId = this.user.idType.id
      return payload
    },
    updateUser() {
      let payload = this.buildPayload()
      api
          .updateUser(this.userId, payload)
          .then(response => {
            if (response.status === 200) {
              this.saveRecommenders(this.userId)
              if (this.errors.length === 0) {
                this.$router.push('/account/view')
              }
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    saveRecommenders(uid) {
      for (const rec of this.recommenders) {
        if (Object.keys(rec).length === 0) {
          continue
        }

        const payload = {
          name: rec.name,
          phoneNumber: rec.phoneNumber
        }
        if (rec.id === undefined) {
          this.createRecommender(uid, payload)
        } else {
          this.updateRecommender(uid, rec.id, payload)
        }
      }
    },
    createRecommender(uid, payload) {
      api
          .createRecommender(uid, payload)
          .catch(err => {
            this.handleError(err)
          })
    },
    updateRecommender(uid, recId, payload) {
      api
          .updateRecommender(uid, recId, payload)
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchQualifications() {
      api
          .fetchQualifications()
          .then(response => {
            this.qualifications = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchRecommenders() {
      api
          .fetchRecommenders(this.userId)
          .then(response => {
            if (response.data.length) {
              this.recommenders = response.data
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      valid: false,
      errors: [],
      userId: null,
      user: {
        accountNumber: null,
        firstName: null,
        middleName: null,
        lastName: null,
        address: null,
        addressLine2: null,
        city: null,
        state: null,
        postalCode: null,
        country: 'US',
        phoneNumber: null,
        email: null,
        birthYear: null,
        gender: null,
        idNumber: null,
        idType: null,
        idExpires: null,
        idIssuedBy: null,
        status: null,
        comment: null,
        qualification: null
      },
      genderOptions: ['Male', 'Female', 'Other'],
      statesList: [],
      countriesList: [],
      idTypes: [],
      qualifications: [],
      recommenders: [{}, {}, {}],
      rules: {
        emailAddress: [
          v => validation.isEmailAddress(v) || 'must be a valid email address'
        ],
        required: [
          v => validation.isRequired(v) || 'required'
        ],
        numeric: [
          v => validation.isInteger(v) || 'must be a number'
        ],
        birthYear: [
          v => validation.isInteger(v) || 'must be a year number',
          v => validation.isMoreThan(v, 1900) || 'must be after 1900',
          v => validation.isBeforeOrEqualToCurrentYear(v) || 'must be before or equal to current year'
        ],
        idExpiration: [
          v => validation.isInteger(v) || 'must be a year number',
          v => validation.isAfterOrEqualToCurrentYear(v) || 'must be after or equal to current year'
        ],
        recommender: [
          v => validation.isRequired(v) || 'required',
          v => (v && validation.isUnique(v, this.recommenders.map(el => el.name))) || 'must be unique'
        ]
      }
    }
  }
}
</script>
